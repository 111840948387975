<template>
  <el-container class="system">
    <el-header>
      <div class="leftNav flex">
        <div class="logo">
          <img src="../assets/img/ysLogoSys.png" alt="">
        </div>
        <i class="el-icon-s-fold btnCollapse" @click="isCollapse=!isCollapse"></i>
      </div>
      <h2 class="title flex">{{ $store.state.setting.systemName }}</h2>
      <div class="right flex">
        <span class="time">{{ new Date() | formatDay1 }}</span>
        <el-dropdown :hide-on-click="false" @command="handleCommand">
          <div class="flex ">
            <el-avatar size="medium" :src="user.avatar"></el-avatar>
            <span class="name">{{ user.nickname }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <el-aside :width="!isCollapse?'180px':''">
        <el-menu unique-opened router class="pl-0" background-color="#EAF3FF" text-color="#333333"
                 active-text-color="#4896FF" :default-active="activeMenu" :collapse="isCollapse">
          <template v-for="(item,index) in routes">
            <el-submenu :key="index" :index="'a'+index" class="navbar" v-if="item.children">
              <template slot="title">
                <i class="icon iconfont" :class="item.icon"></i>
                <span slot="title">{{ item.name }}</span>
              </template>
              <el-menu-item
                  v-for="(child,ind) in item.children"
                  :index="child.path"
                  :key="child.path">
                <i class="icon iconfont" :class="child.icon"></i>
                <span class="name">{{ child.name }}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item :index="item.path" v-else>
              <i class="icon iconfont" :class="item.icon"></i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
          <router-view/>
        </el-main>
        <el-footer height="40px" v-html=" $store.state.setting.copyright">
        </el-footer>
      </el-container>
    </el-container>
  </el-container>

</template>

<script>
import {mapState} from 'vuex'
import request from "../api/request";

export default {
  name: "sys",
  data() {
    return {
      navbar: [],
      active: -1,
      activeA: -1,
      isCollapse: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    }),
    routes() {
      let routes = this.$local.get('USER_ROUTER')
      if (Object.keys(routes).length === 0) {
        request.get(`oauth/menu/${this.user.username}`).then((res) => {
          localStorage.setItem('USER_ROUTER', JSON.stringify(res.data))
          return res.data;
        })
      }
      return routes;
    },
    activeMenu() {
      return this.$route.meta.closeable ? this.$route.meta.jumpPath : this.$route.path
    }
  },
  methods: {
    handleCommand(command) {
      if (command == 'a') {
        this.$get("/logout/" + this.user.activeUserId).then(() => {
          this.$local.clear();
          location.reload()
          this.$router.push({path: '/'});
        }).catch(() => {
          this.$message.error("退出失败")
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/layout";
</style>
<style>
@import "../assets/font/iconfont.css";
</style>
